import { ClientOnly } from "remix-utils/client-only";
import AnimationClient from "~/animation.client.tsx";

export default function Animation({
  animationData,
  loop,
}: {
  animationData: object;
  loop: boolean;
}) {
  return (
    <ClientOnly fallback={<></>}>
      {() => <AnimationClient animationData={animationData} loop={loop} />}
    </ClientOnly>
  );
}
