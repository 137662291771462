import { Stack, Typography } from "@mui/material";
import defaultLogo from "./notaires.svg";
import { useLoaderData } from "@remix-run/react";

export default function () {
  const { logoUrl, officeName } = useLoaderData<{
    logoUrl?: string | null;
    officeName?: string | null;
  }>();
  return (
    <Stack alignItems="center">
      <img
        alt={`Logo ${officeName}`}
        src={logoUrl || defaultLogo}
        style={{
          maxHeight: logoUrl ? "100px" : "70px",
          ...(logoUrl ? { maxWidth: "327px" } : {}),
        }}
      />
      {!logoUrl && (
        <Typography variant="h4" sx={{ marginBottom: "10px" }}>
          {officeName}
        </Typography>
      )}
    </Stack>
  );
}
