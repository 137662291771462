import { useEffect } from "react";
import * as Sentry from "@sentry/remix";

export default function useSentry() {
  useEffect(() => {
    Sentry.init({
      dsn: "https://79365c5746540a8039cb6000a8c391ec@o4507465129590784.ingest.de.sentry.io/4508626348146768",
      tracesSampleRate: 1,
      integrations: [],
    });
  }, []);
}
