import Player from "lottie-react";

export default function AnimationClient({
  animationData,
  loop,
}: {
  animationData: object;
  loop: boolean;
}) {
  return <Player animationData={animationData} loop={loop} />;
}
